import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

function Pagination({ currentPage, totalPages, onPageChange }) {
  const maxPageButtons = 5;

  const renderPageButtons = () => {
    const pageButtons = [];
    const halfMaxButtons = Math.floor(maxPageButtons / 2);

    let startPage = currentPage - halfMaxButtons;
    let endPage = currentPage + halfMaxButtons;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, maxPageButtons);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPageButtons + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <span key={i} className={`pagination-item ${currentPage === i ? 'current-page' : ''}`} onClick={() => onPageChange(i)} >
          {i}
        </span>
      );
    }
    return pageButtons;
  };

  return (
  <>
    <div className="pagination">
      {currentPage > 1 && (
        <span className={`pagination-item ${currentPage === 1 ? 'current-page' : ''}`} onClick={() => onPageChange(currentPage - 1)}>
          <FontAwesomeIcon icon={faChevronLeft} className="prev-arrow" />
        </span>
      )}

      {renderPageButtons()}

      {/* <FontAwesomeIcon icon={faEllipsis} className="page-decor" /> */}

      {currentPage < totalPages && (
        <span className={`pagination-item ${currentPage === totalPages ? 'current-page' : ''}`} onClick={() => onPageChange(currentPage + 1)}>
          <FontAwesomeIcon icon={faChevronRight} className="next-arrow" />
        </span>
      )}
    </div>
  </>
  );
}
export default Pagination;
