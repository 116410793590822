const searchProducts = (products, query) => {
  return products.filter((product) => {
    const titleMatches = product.title.toLowerCase().includes(query.toLowerCase());
    const keywordsMatch = product.keywords.some((keyword) =>
      keyword.toLowerCase().includes(query.toLowerCase())
    );

    return titleMatches || keywordsMatch;
  });
};
export default searchProducts;
