import React from 'react';
import { Link } from 'react-router-dom';

function Hero() {
  return (
    <div className="hero-wrapper">
      <div className="bg-logo">
        <img src={process.env.PUBLIC_URL + "/images/tee-whisper-logo-text.png"} alt="Logo"/>
      </div>
      <div className="hero">
        <Link to="/">
          <div className="hero__logo" data-aos="fade-down">
            <img src={process.env.PUBLIC_URL + "/images/tee-whisper-logo.png"} alt="Logo"/>
          </div>
        </Link>
        <div className="hero__content">
          <h1 data-aos="zoom-in">Crafting Tee Stories</h1>
          <div className="shirts-display">
            <div className="shirts-display__shirt1" data-aos="fade-up">
              <img className='bouncing-slow' src={process.env.PUBLIC_URL + "/images/shirt-display-tshirt-blue.png"} alt="Logo"/>
            </div>
            <div className="shirts-display__shirt2" data-aos="fade-up">
              <img className='bouncing-rev-slow' src={process.env.PUBLIC_URL + "/images/shirt-display-tshirt-white.png"} alt="Logo"/>
            </div>
            <div className="shirts-display__shirt3" data-aos="fade-up">
              <img className='bouncing-slow' src={process.env.PUBLIC_URL + "/images/shirt-display-tshirt-grey.png"} alt="Logo"/>
            </div>
          </div>
        </div>
      </div>
      <div className="hero__scroll-down">
        <div className="scroll-down">
          <div className="arrow"></div>
          <div className="arrow"></div>
          <div className="arrow"></div>
          <span className="text">Scroll Down</span>
        </div>
      </div>
    </div>
  );
}
export default Hero;
