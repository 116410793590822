import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Welcome from './pages/Welcome';
import HomeUS from './pages/HomeUS';
import HomeGB from './pages/HomeGB';
import NotFound from './pages/NotFound';

function App() {
  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <Router>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/us/*" element={<HomeUS />} />
          <Route path="/gb/*" element={<HomeGB />} />
          <Route path="*" element={<NotFound />} /> {/* Add this catch-all route */}
        </Routes>
      </Router>
    </HelmetProvider>
  );
}
export default App;
