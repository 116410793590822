import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.scss"
import App from "./App";


//Third Party
import AOS from "aos"
import 'aos/dist/aos.css';


// Create a root for your application
const root = document.getElementById('root');
const appRoot = ReactDOM.createRoot(root);


// Render your app using the createRoot API
appRoot.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


//Initialising Aos library
AOS.init({
  duration: 1400
});
