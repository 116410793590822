import React from 'react';

function About() {
  return (
    <>
      <div className="home__content__about">
        <h2>We are designers!</h2>
        <h4>We specialize in crafting humorous and engaging T-shirt narratives tailored to various occasions. Our aim is to infuse your family gatherings with laughter and merriment, be it birthdays, Father's Day, Christmas, Thanksgiving, retirement parties, anniversaries, St. Patrick's Day, the 4th of July, reunions, and countless other moments of celebration.</h4>
      </div>
      <div className="home__content__amz">
        <span>All our desings can be purchased on <img className='amz-logo' src={process.env.PUBLIC_URL + "/images/amazon-logo.png"} alt="Logo"/></span>
        <span>Simply click the design you like!</span>
      </div>
    </>
  );
}
export default About;
