import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeoplePulling } from '@fortawesome/free-solid-svg-icons';

function NotFound() {
  return (
    <div className='not-found-wrapper'>
      <div className="bg-logo">
        <img src={process.env.PUBLIC_URL + "/images/tee-whisper-logo-text.png"} alt="Logo"/>
      </div>
      <div className="not-found">
        <Link to="/">
          <div className="not-found__logo" data-aos="fade-down">
            <img src={process.env.PUBLIC_URL + "/images/tee-whisper-logo.png"} alt="Logo"/>
          </div>
        </Link>
        <div className="not-found__content">
          <h4 data-aos="fade-in">Ups... Something went wrong</h4>
          <h1 data-aos="zoom-in">404</h1>
          <Link to="/">
            <div className="go-back">
              <FontAwesomeIcon icon={faPeoplePulling} className='go-back__icon'/>
              <span>Go Back</span>
            </div>
          </Link>
        </div>
      </div>
      <footer>
        <div className="copyrights">
          <p>{new Date().getFullYear()} © TeeWhisper | Developed & Managed by <a href="http://webcraff.com" target='_blank' rel="noreferrer noopener">Webcraff</a></p>
        </div>
      </footer>
    </div>
  );
}
export default NotFound;
