import React from 'react';
import { Helmet } from 'react-helmet-async';
import Shirts from '../components/Shirts/Shirts';
import About from '../layouts/About';
import Hero from '../layouts/Hero';
import Cta from '../layouts/Cta';
import Footer from '../layouts/Footer';


function HomeGB() {
  return (
    <div className="home-wrapper">
      <Helmet>
        <meta name="description" content="Welcome to TeeWhisper where funny tee stories are created! We're your one-stop shop for creative & appealing T-shirt designs that help people remember you!"/>
      </Helmet>
      <Hero />
      <div className='home'>
        <div className="home__content">
          <About />
          <Shirts />
          <Cta />
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default HomeGB;
