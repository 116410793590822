import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

function Cta() {
  return (
    <div className="home__content__cta">
      <h2>NEED TEES FOR SPECIAL OCCASION?</h2>
      <h4>We can create individual design for your company events, wedding or other occasion and list your shirts on Amazon, so everyone can buy them individually or you can buy all of them together! </h4>
      <div className='contact'>
        <Link to="mailto:info@teewhisper.com">
          <h3>Let's talk about your design!</h3>
        </Link>
        <Link to="mailto:info@teewhisper.com">
          <FontAwesomeIcon icon={faEnvelope} className='envelope'/>
        </Link>
      </div>
    </div>
  );
}
export default Cta;







