import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function Welcome() {
  return (
    <div className='welcome-wrapper'>
      <Helmet>
        <meta name="description" content="Welcome to TeeWhisper where funny tee stories are created! We're your one-stop shop for creative & appealing T-shirt designs that help people remember you!"/>
      </Helmet>
      <div className="bg-logo">
        <img src={process.env.PUBLIC_URL + "/images/tee-whisper-logo-text.png"} alt="Logo"/>
      </div>
      <div className="welcome">
        <div className="welcome__logo" data-aos="fade-down">
          <img src={process.env.PUBLIC_URL + "/images/tee-whisper-logo.png"} alt="Logo"/>
        </div>
        <div className="welcome__content">
          <h4 data-aos="fade-in">Welcome to Tee Whisper!</h4>
          <h1 data-aos="zoom-in">CHOOSE YOUR MARKET</h1>
          <div className="market-btns" data-aos="fade-up" >
            <Link to="/us">
              <div className='market-btns__us-btn'>
                <img className="bouncing" src={process.env.PUBLIC_URL + "/images/button-us-market-tshirt.png"} alt="Logo"/>
              </div>
            </Link>
            <Link to="/gb">
              <div className='market-btns__gb-btn'>
                <img className="bouncing-rev" src={process.env.PUBLIC_URL + "/images/button-gb-market-tshirt.png"} alt="Logo"/>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <footer>
        <div className="copyrights">
          <p>{new Date().getFullYear()} © TeeWhisper | Developed & Managed by <a href="http://webcraff.com" target='_blank' rel="noreferrer noopener">Webcraff</a></p>
        </div>
      </footer>
    </div>
  );
}
export default Welcome;
