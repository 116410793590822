import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function ShirtCardGB({ asin }) {
  const [imageUrl, setImageUrl] = useState("");
  const [productData, setProductData] = useState([]);
  const [title, setTitle] = useState("");

  const isLocal = process.env.NODE_ENV === "development";
  const apiBaseUrl = isLocal
    ? process.env.REACT_APP_LOCAL_API_BASE_URL
    : process.env.REACT_APP_PROD_API_BASE_URL;

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/api/products`);
        setProductData(response.data);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };
    fetchProductData();
  }, [apiBaseUrl]);

  useEffect(() => {
    //console.log("Fetching image for ASIN:", asin);
    axios
      .get(`${apiBaseUrl}/api/products/gb/${asin}`)
      .then((response) => {
        //console.log("Image fetched successfully:", response.data.imageUrl);
        setImageUrl(response.data.imageUrl);
      })
      .catch((error) => {
        console.log("API URL:", apiBaseUrl);
        console.error("Error fetching image:", error);
        console.log("Error response data:", error.response.data);
      });

    const product = productData.find((product) => product.ASIN_GB === asin);
    if (product) {
      setTitle(product.title);
    }
  }, [asin, productData, apiBaseUrl]);

  return (
    <div className="shirt-card">
      <Link to={`https://www.amazon.co.uk/dp/${asin}`} target="_blank">
        <div className="shirt-card__amz-btn">
          <span>Buy at</span>
          <img src={process.env.PUBLIC_URL + "/images/amazon-logo-white.png"} alt="Logo" />
        </div>
      </Link>
      <Link to={`https://www.amazon.co.uk/dp/${asin}`} target="_blank">
        <div className="shirt-card__shirt">
          {imageUrl ? (
            <img src={apiBaseUrl + imageUrl} alt={title + " Funny T-shirt Gift for Birthday Christmas Celebration Party"} />
          ) : (
            <div className="spinner">
              <span>Loading...</span>
              <div className="spinner__wrapper">
                <span className="loader"></span>
              </div>
            </div>
          )}
        </div>
      </Link>
      <Link to={`https://www.amazon.co.uk/dp/${asin}`} target="_blank">
        <h5 className="shirt-card__title">{title}</h5>
      </Link>
      <div className="shirt-card__divider"></div>
      <div className="shirt-card__colors">
        <div className="color-dots">
          <img alt="Asphalt Grey" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Asphalt Grey.jpeg"}/>
          <img alt="Baby Blue" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Baby Blue.jpeg"}/>
          <img alt="Black" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Black.jpeg"}/>
          <img alt="Brown" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Brown.jpeg"}/>
          <img alt="Cranberry Red" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Cranberry Red.jpeg"}/>
          <img alt="Dark Heather Grey" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Dark Heather Grey.jpeg"}/>
          <img alt="Grass Green" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Grass Green.jpeg"}/>
          <img alt="Heather Blue" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Heather Blue.jpeg"}/>
          <img alt="Heather Grey" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Heather Grey.jpeg"}/>
          <img alt="Kelly Green" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Kelly Green.jpeg"}/>
          <img alt="Lemon Yellow" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Lemon Yellow.jpeg"}/>
          <img alt="Navy Blue" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Navy Blue.jpeg"}/>
          <img alt="Olive Green" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Olive Green.jpeg"}/>
          <img alt="Orange" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Orange.jpeg"}/>
          <img alt="Pink" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Pink.jpeg"}/>
          <img alt="Purple" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Purple.jpeg"}/>
          <img alt="Red" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Red.jpeg"}/>
          <img alt="Royal Blue" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Royal Blue.jpeg"}/>
          <img alt="Silver Grey" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Silver Grey.jpeg"}/>
          <img alt="White" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/White.jpeg"}/>
          <img alt="Slate Grey" className="color-dot" src={process.env.PUBLIC_URL + "/images/colors/Slate Grey.jpeg"}/>
        </div>
        <span>* Colors subject to availability</span>
      </div>
    </div>
  );
}

export default ShirtCardGB;
