import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faTimes } from '@fortawesome/free-solid-svg-icons';

import ShirtCardUS from './ShirtCardUS';
import ShirtCardGB from './ShirtCardGB';
import searchProducts from '../../utils/search';
import Pagination from '../../utils/pagination';

function Shirts() {
  const location = useLocation();
  const [asins, setAsins] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredAsins, setFilteredAsins] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isResetActive, setIsResetActive] = useState(false); // Track reset button's active state
  const [cardsToShow, setCardsToShow] = useState(20); // Track the number of cards to display
  const [productData, setProductData] = useState([]);
  const isLocal = process.env.NODE_ENV === 'development';

  const apiBaseUrl = isLocal
    ? process.env.REACT_APP_LOCAL_API_BASE_URL
    : process.env.REACT_APP_PROD_API_BASE_URL;

  // Accessing API from Server level
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/api/products`);
        setProductData(response.data);
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };
    fetchProductData();
  }, [apiBaseUrl, productData]);

  // useEffect(() => {
  //   console.log("productData Array Test", productData);
  // }, [productData]);

  //
  useEffect(() => {
    const marketKey = location.pathname.includes('/us') ? 'ASIN_US' : 'ASIN_GB';
    const extractedAsins = productData
      .filter((product) => product[marketKey])
      .map((product) => product[marketKey]);

    setAsins(extractedAsins);
  }, [location.pathname, productData]);

  useEffect(() => {
    // Use the searchProducts function from the "search.js" file to filter products
    const filteredProducts = searchProducts(productData, searchQuery);
    const marketKey = location.pathname.includes('/us') ? 'ASIN_US' : 'ASIN_GB';
    const filteredAsins = filteredProducts
      .filter((product) => product[marketKey])
      .map((product) => product[marketKey]);

    setFilteredAsins(filteredAsins);
  }, [searchQuery, location.pathname, productData]);

  const renderShirtCards = () => {
    const displayAsins = getCurrentPageData();

    if (location.pathname.includes('/us')) {
      return displayAsins.map((asin) => <ShirtCardUS key={asin} asin={asin} />);
    } else if (location.pathname.includes('/gb')) {
      return displayAsins.map((asin) => <ShirtCardGB key={asin} asin={asin} />);
    } else {
      //return null;
      console.log("Error Loading Cards")
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1); // Reset to the first page when a new search query is entered
    setIsResetActive(query.length > 0); // Set reset button active when there's input
  };

  const handleReset = () => {
    setSearchQuery('');
    setIsResetActive(false); // Deactivate reset button
  };

  // Calculate the total number of pages
  const itemsPerPage = 20;
  const totalItems = searchQuery ? filteredAsins.length : asins.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + cardsToShow; // Use cardsToShow instead of fixed itemsPerPage
    return searchQuery ? filteredAsins.slice(startIndex, endIndex) : asins.slice(startIndex, endIndex);
  };

  // Function to handle "Load More" button click
  const handleLoadMore = () => {
    const newCardsToShow = cardsToShow + 16; // Increase the number of cards to show
    setCardsToShow(newCardsToShow);
  };

  return (
    <>
      <div className="home__content__filters">
        <div className="search-bar">
          <input className='search-field' id="search-field" type="text" placeholder="Find your design..." value={searchQuery} onChange={(e) => handleSearch(e.target.value)} />
          <FontAwesomeIcon icon={faTimes} className={`reset-search-btn ${isResetActive ? 'active' : ''}`} onClick={handleReset} />
          <button className="search-btn btn-default">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="magnifying-glass" />
            <span>SEARCH</span>
          </button>
        </div>
      </div>
      <div className="pagination-small">
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={(newPage) => setCurrentPage(newPage)} />
      </div>
      <div className="home__content__shirts">
        {renderShirtCards()}
      </div>
      {/* Check if there are more cards to load */}
      {cardsToShow < (searchQuery ? filteredAsins.length : asins.length) && (
        <button className='load-more-btn btn-default' onClick={handleLoadMore}>Load more</button>
      )}
      <Pagination className="pagination-big" currentPage={currentPage} totalPages={totalPages} onPageChange={(newPage) => setCurrentPage(newPage)} />
    </>
  );
}

export default Shirts;
