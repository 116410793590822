import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';


function Footer() {

  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
      window.addEventListener("scroll", () => {
        const contentHeight = document.documentElement.scrollHeight;
        const screenHeight = window.innerHeight;
        const halfWayPoint = (contentHeight / 2) - (screenHeight);

        if (window.scrollY > halfWayPoint) {
          setShowTopBtn(true);
        } else {
          setShowTopBtn(false);
        }
      });
  }, []);

  const goToTop = () => {
      window.scrollTo({
          top: 0,
      });
  };

  return (
    <footer className="home__footer">
      <div className="home__footer__logo">
        <Link to="/" rel="noreferrer noopener">
          <img src={process.env.PUBLIC_URL + "/images/tee-whisper-logo.png"} alt="Logo"/>
        </Link>
      </div>
      <div className="home__footer__social">
        <Link to="https://www.facebook.com/teewhisper" target='_blank' rel="noreferrer noopener">
          <FontAwesomeIcon  icon={faFacebookF} className='facebook-icon' />
        </Link>
        <Link to="https://www.instagram.com/teewhisper" target='_blank' rel="noreferrer noopener">
          <FontAwesomeIcon  icon={faInstagram} className='instagram-icon' />
        </Link>
        <Link to="https://www.tiktok.com/@teewhisper" target='_blank' rel="noreferrer noopener">
          <FontAwesomeIcon  icon={faTiktok} className='tiktok-icon' />
        </Link>
      </div>

      {showTopBtn && (
        <div className="home__footer__btt-btn" onClick={goToTop}>
          <span className="back-to-top-btn" ><FontAwesomeIcon icon={faArrowUpFromBracket} /></span>
        </div>
      )}

      <div className="home__footer__copyrights">
        <p>{new Date().getFullYear()} © TeeWhisper | Developed & Managed by <Link to="http://webcraff.com" target='_blank' rel="noreferrer noopener">Webcraff</Link></p>
      </div>
    </footer>
  );
}
export default Footer;
